import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { useLocation } from 'react-router-dom'

const getPageCompare = (pathname, title) => {
	const currentPage = {
	   "/om-oss": "Om oss",
	   "/sa-funkar-det": "Så funkar det",
	   "/kundcase": "Kundcase",
	   "/lararteamet": "Lärarteamet",
		"/begar-offert": "Begär offert"
	 }
	 return currentPage[pathname] === title
}

const PageButton = (props) => {
	const [isActive, setIsActive] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setIsActive(getPageCompare(location.pathname, props.title))
	}, [location.pathname, props.title])

	return (
		<React.Fragment>
			<Button style={{marginRight: '20px', color: 'white'}} active={isActive}>
				{props.title}
			</Button>
		</React.Fragment>
	)
}

export default PageButton