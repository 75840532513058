import React, { lazy, Suspense } from 'react'
import Loader from 'react-loaders'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// Layout
import AppHeader from '../../Layout/AppHeader'

//Multiple schooltypes
const GenericSchoolType = lazy(() => import('pages/SchoolTypes/GenericSchoolType'))

const Gymnasieskola = lazy(() => import('pages/SchoolTypes/Gymnasieskola'))
const Folkhogskola = lazy(() => import('pages/SchoolTypes/Folkhogskola'))
const Grundskola = lazy(() => import('pages/SchoolTypes/Grundskola'))
const Vuxenutbildning = lazy(() => import('pages/SchoolTypes/Vuxenutbildning'))
const Auth = lazy(() => import('pages/Auth'))
const LandingPage = lazy(() => import('pages/LandingPage'))
const Teachers = lazy(() => import('pages/Teachers'))
const HowItWorks = lazy(() => import('pages/HowItWorks'))
const Contact = lazy(() => import('pages/Contact'))
const AboutUs = lazy(() => import('pages/AboutUs'))
const Pricelist = lazy(() => import('pages/Pricelist'))
const VideoLink = lazy(() => import('pages/VideoLink'))
const LessonLink = lazy(() => import('pages/LessonLink'))
const NoContent = lazy(() => import('pages/NoContent'))

const AppMain = () => {
	return (
		<React.Fragment>
			<Suspense
				fallback={
					<div className="loader-container">
						<div className="loader-container-inner">
							<div className="text-center">
								<Loader type="ball-pulse-rise" />
							</div>
							<h6 className="mt-5">Laddar innehåll</h6>
						</div>
					</div>
				}
			>
			<AppHeader/>
			<Switch>
				<Route path="/auth" component={Auth} />
				<Route path="/lararteamet" component={Teachers} />
				<Route path="/sa-funkar-det" component={HowItWorks} />
				<Route path="/om-oss" component={AboutUs} />
				<Route path="/kundcase" component={Contact} />
				<Route path="/begar-offert" component={Pricelist} />
				<Route path="/video/*" component={VideoLink} />
				<Route path="/lesson/*" component={LessonLink} />
				<Route path='/folkhogskola' component={Folkhogskola}/>
				<Route path='/grundskola' component={Grundskola}/>
				<Route path='/gymnasieskola' component={Gymnasieskola}/>
				<Route path='/vuxenutbildning' component={Vuxenutbildning}/>
				<Route exact path="/" component={LandingPage} />
			
				<Route path={`/:schooltype/:subject/:course/:area/:id`} component={GenericSchoolType} />
				
				<Route path="*"
					render={(props) => (
						<NoContent {...props} style={{paddingTop: '75px'}} title="Sidan kunde inte hittas" text="Oj, sidan som du försöker nå finns inte." />
					)}
				/>
			</Switch>
			</Suspense>
			<ToastContainer />
		</React.Fragment>
	)
}
export default AppMain
