import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav } from 'reactstrap'

import PageButton from '../../../components/Header/PageButton'

const MegaMenu = () => {

	return (
		<React.Fragment>
			<Nav>
				<NavLink to="/om-oss">
					<PageButton title={'Om oss'}/>
				</NavLink>
				<NavLink to="/sa-funkar-det">
					<PageButton title={'Så funkar det'}/>
				</NavLink>
				<NavLink to="/kundcase">
					<PageButton title={'Kundcase'}/>
				</NavLink>
				<NavLink to="/lararteamet">
					<PageButton title={'Lärarteamet'}/>
				</NavLink>
				<NavLink to="/begar-offert">
					<PageButton title={'Begär offert'}/>
				</NavLink>
			</Nav>
		</React.Fragment>
	)
}

export default MegaMenu
